import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Layout from '@components/layout';
import PageComponents from '@components/layout/PageComponents/PageComponents';

// === Content === //
import { AboutUsDTO } from '@DTO/AboutUsDTO';

// === Helpers === //
import { breadcrumbsObject, organizationObject } from '@helpers/schemaOrg';

// === Types === //
import { TemplatePageContextData } from '@type/index.d';

interface AboutUsPageProps {
  pageContext?: TemplatePageContextData;
}

const AboutUsPage = ({ pageContext }: AboutUsPageProps) => {
  const aboutUsPage = AboutUsDTO(pageContext?.uuid);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsObject(aboutUsPage.breadcrumbs.list))}
        </script>
        <script type="application/ld+json">{JSON.stringify(organizationObject())}</script>
      </Helmet>
      <Layout
        metaData={aboutUsPage.metaData}
        breadcrumbs={aboutUsPage.breadcrumbs}
        stickedForm={aboutUsPage.stickedForm}
        bannerContactInfo={undefined}
        navLangPath={aboutUsPage.langPath}
      >
        <PageComponents components={aboutUsPage.components} slug={`/${pageContext?.slug || ''}`} />
      </Layout>
    </>
  );
};

export default AboutUsPage;
